import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "login",
      component: () => import("@/view/pages/authentication/login"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/recover-password",
      name: "recover",
      component: () => import("@/view/pages/authentication/recover"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/reset-password",
      name: "reset",
      component: () => import("@/view/pages/authentication/reset"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/register",
      name: "register",
      component: () => import("@/view/pages/authentication/register"),
      meta: {
        layout: "full",
      },
    },

    // MAIN
    {
      path: "/dashboards/analytics",
      name: "dashboards-analytics",
      component: () => import("@/view/main/dashboards/analytics"),
    },
    {
      path: "/pages/pricing",
      name: "pricing",
      component: () => import("@/view/pages/pricing"),
      meta: {
        title: "Pricing",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/personel-information",
      name: "profile-personel-information",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/notifications",
      name: "profile-notifications",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/activity",
      name: "profile-activity",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/security",
      name: "profile-security",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/password-change",
      name: "profile-password-change",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/pages/profile/connect-with-social",
      name: "profile-connect-with-social",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/applications/knowledge-base/knowledge-base-1",
      name: "knowledge-base-1",
      component: () => import("@/view/pages/knowledge-base/knowledge-base-1"),
      meta: {
        title: "Knowledge Base-1",
        breadcrumb: [
          {
            text: "Pages",
          },
        ],
      },
    },
    {
      path: "/main/widgets/cards/statistics",
      name: "statistics",
      component: () => import("@/view/main/widgets/cards/statistics"),
      meta: {
        title: "Estatisticas",
        breadcrumb: [
          {
            text: "Main",
          },
          {
            text: "Widgets",
          },
          {
            text: "Cards",
          },
        ],
      },
    },
    {
      path: "/main/widgets/cards/analytics",
      name: "analytics",
      component: () => import("@/view/main/widgets/cards/analytics"),
      meta: {
        title: "Análise",
        breadcrumb: [
          {
            text: "Main",
          },
          {
            text: "Widgets",
          },
          {
            text: "Cards",
          },
        ],
      },
    },
  ],
});

export default router;
